import { axios } from '@/utils/request'

const api = {
    chargePages: 'charging/charge/pages', // 计时收费方案分页查询
    insertAdd: 'charging/charge/insert', // 新增计时收费方案
    update: 'charging/charge/update', // 修改计时收费方案
    delete: 'charging/charge/delete', // 删除计时收费方案
}
export default api

// 查询
export const chargePagesApi = (params) => {
    return axios({
        url: api.chargePages,
        method: 'post',
        params
    })
}

// 新增
export const insertAddApi = (data) => {
    return axios({
        url: api.insertAdd,
        method: 'post',
        data
    })
}

// 修改
export const updateApi = (data) => {
    return axios({
        url: api.update,
        method: 'post',
        data
    })
}

// 删除
export const deleteApi = (params) => {
    return axios({
        url: api.delete,
        method: 'post',
        params
    })
}