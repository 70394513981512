<!--收费方案列表 -->
<template>
  <div class="warp">
    <el-card class="warp-content">
      <!-- 搜素区域 -->
      <div class="page-select">
        <el-row style="width: 100%; margin: 0 0 15px 0">
          <el-col :span="24" style="text-align: right">
            <el-input
              placeholder="请输入方案名称"
              class="search-input"
              prefix-icon="search"
              size="small"
              @input="searchInput"
              v-model="chargeNameValue"
            >
            </el-input>
            <el-button type="primary" size="small" @click="searchClick"
              >搜索</el-button
            >
            <el-button type="primary" size="small" @click="addClick" v-if="userLevel === 0"
              >新增方案</el-button
            >
          </el-col>
        </el-row>
      </div>

      <!-- 表格区域 -->
      <div class="page-container">
        <el-table
          ref="tableRef"
          :data="tableData"
          style="width: 100%"
          max-height="630px"
          :header-cell-style="{ background: '#F2F4F8' }"
          @selection-change="handleSelectionChange"
        >
<!--          <el-table-column type="selection" align="center" width="50" />-->
          <el-table-column
            type="index"
            align="center"
            label="序号"
            width="80"
          />
          <el-table-column
            v-for="(item, index) in tableColumnData"
            :key="index"
            :prop="item.prop"
            :label="item.label"
            align="center"
          >
          </el-table-column>
            <el-table-column label="操作" align="center" v-if="userLevel === 0">
              <template #default="{ row }">
                <el-button type="text" @click="editClick(row)">编辑</el-button>
                <el-divider direction="vertical"></el-divider>
                <el-popconfirm
                    confirm-button-text="确定"
                    cancel-button-text="取消"
                    @confirm="confirmEvent(row)"
                    :title="'确认是否删除' + '“' + row.chargeName + '”' + '方案？'"
                >
                  <template #reference>
                    <el-button type="text">删除</el-button>
                  </template>
                </el-popconfirm>
              </template>
            </el-table-column>
        </el-table>
      </div>
      <!-- 分页区域 -->
      <div class="page-foot">
        <page-nation
          :total="total"
          @pageChange="handleSizeChange"
          @currentChange="handleCurrentChange"
        ></page-nation>
      </div>
      <!--方案名称-->
      <el-dialog
        v-model="dialogVisible"
        width="40%"
        :title="dialogTitleName"
        :close-on-click-modal="false"
        @closed="resetForm"
      >
        <el-form
          label-width="95px"
          :inline="true"
          :model="FormData"
          :rules="rules"
          ref="ruleForm"
        >
          <el-row :gutter="20">
            <el-col :span="22">
              <el-form-item label="方案名称：" prop="chargeName">
                <el-input
                  placeholder="请输入方案名称"
                  v-model="FormData.chargeName"
                  width="100%"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="22">
              <el-form-item label="计时类型：" prop="chargeScheme">
                <el-radio-group v-model="FormData.chargeScheme">
                  <el-radio
                    v-for="item in timingTypeOptions"
                    :key="item.value"
                    :label="item.value"
                    >{{ item.label }}</el-radio
                  >
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="22">
              <el-form-item label="详情：">
                <el-row>
                  <!-- <el-col :span="24">
                    <el-button
                      type="primary"
                      size="small"
                      @click="handleAddDetailClick"
                      >添加详情</el-button
                    >
                  </el-col> -->
                  <el-col :span="2"> 时间 </el-col>
                  <el-col :span="4">
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    1小时
                    <!-- <el-select
                      v-model="FormData.chargeDuration"
                      placeholder="请选择时间"
                      size="small"
                      disabled
                    >
                      <el-option
                        v-for="item in timeOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select> -->
                  </el-col>
                  <el-col :span="3" :offset="1"> 收费金额 </el-col>
                  <el-col :span="8">
                    <el-input
                      placeholder="请输入收费金额"
                      v-model="FormData.chargeMoney"
                      controls-position="right"
                      size="small"
                      style="width: 100%"
                    ></el-input>
                  </el-col>
                </el-row>
                <!-- 多个详情 -->
                <!-- <el-row v-for="(item, index) in detailNum" :key="index">
                  <el-col :span="2"> 时间 </el-col>
                  <el-col :span="8">
                    <el-select
                      v-model="item.atime"
                      placeholder="请选择时间"
                      size="small"
                    >
                      <el-option
                        v-for="item in timeOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </el-col>
                  <el-col :span="3" :offset="1"> 收费金额 </el-col>
                  <el-col :span="8">
                    <el-input-number
                      placeholder="请输入收费金额"
                      v-model="item.price"
                      controls-position="right"
                      size="small"
                      style="width: 100%"
                    ></el-input-number>
                  </el-col>
                  <el-col :span="2">
                    <el-button
                      type="danger"
                      size="small"
                      @click="handleDelDetailClick(item, index)"
                      style="margin-left: 10px"
                      >删除</el-button
                    >
                  </el-col>
                </el-row> -->
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="resetEditForm">取消 </el-button>
            <el-button type="primary" @click="submitEditForm('ruleForm')"
              >保存
            </el-button>
          </span>
        </template>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, getCurrentInstance } from "vue-demi";
import pageNation from "@/components/base/pageNation.vue";
import { requestApi } from "@/utils/requestApi";
import { debounces } from "@/common/utils";
import {
  chargePagesApi,
  insertAddApi,
  updateApi,
  deleteApi,
} from "@/api/chargingScheme/timeChargingScheme/TimeChargingScheme";
export default {
  name: "TimeChargingScheme",
  components: { pageNation },
  setup() {
    const { proxy } = getCurrentInstance();
    const data = reactive({
      chargeNameValue: null, //搜索方案名称
      tableData: [], // 表格数据
      total: 0,
      pageSize: 10,
      currentPage: 1,
      userInfo: null,
      userLevel: 0, //用户等级
      selection: [],
      disabled: true,
      tableColumnData: [
        {
          prop: "chargeName",
          label: "方案名称",
        },
        {
          prop: "schemeDetails",
          label: "方案详情",
        },
        {
          prop: "chargeScheme",
          label: "计费方案",
        },
        {
          prop: "createUser",
          label: "创建人",
        },
        {
          prop: "createTime",
          label: "创建时间",
        },
      ],
      chargeId: null, // 编辑方案的id
      // 编辑用户对话框
      dialogVisible: false,
      dialogTitleName: "新增方案",
      // 编辑用户form
      FormData: {
        chargeName: "", // 方案名称
        chargeScheme: 2, // 选中的计时类型
        // chargeDuration: [], // 方案详情
        chargeDuration: 1, // 时间
        chargeMoney: 0.5, //金额
      },
      rules: {
        chargeName: {
          required: true,
          message: "请输入方案名称",
          trigger: ["blur", "change"],
        },
        chargeScheme: {
          required: true,
          message: "请选择计时类型",
          trigger: ["blur", "change"],
        },
      },
      // 计时类型
      timingTypeOptions: [
        { value: 0, label: "按1分钟收费" },
        { value: 1, label: "按30分钟收费" },
        { value: 2, label: "按每小时收费" },
      ],
      chargeScheme: 2, // 选中的计时类型
      timeOptions: [
          // {value: '1', label: '1小时',}
      ], // 详情时间选择
      // detailNum: [{ atime: "1", chargeMoney: "0.5" }],
      detailNum: [{ chargeDuration: "1", chargeMoney: "0.5" }],
    });
    onMounted(() => {
      proxy.userInfo = JSON.parse(localStorage.getItem("userInfo"));
      proxy.$nextTick(() =>{
        proxy.userLevel = proxy.userInfo.chargingMerchant.level
      })
      methods.getTimeOption();
      methods.getChargePages();
    });
    // 节流搜索按钮
    const searchInput = debounces(() => {
      methods.getChargePages();
    }, 500);
    const methods = {
      // 计算时间选择的选项 循环出31小时以下的
      getTimeOption() {
        for (let i = 1; i < 31; i++) {
          data.timeOptions.push({
            value: i + '',
            label: `${i}小时`,
          });
        }
      },
      // 添加详情按钮
      handleAddDetailClick() {
        data.detailNum.push({ atime: "1", price: "0.5" });
      },
      // 删除详情按钮
      handleDelDetailClick(item, index) {
        data.detailNum.splice(index, 1);
      },
      // 新增方案
      addClick() {
        data.FormData = {
          chargeName: "", // 方案名称
          chargeScheme: 2, // 选中的计时类型
          // chargeDuration: [], // 方案详情
          chargeDuration: '1', // 时间
          chargeMoney: 0.5, //金额
        };
        proxy.dialogTitleName = "新增方案";
        data.dialogVisible = true;
        // proxy.detailNum = [{ atime: "1", price: "0.5" }];
      },
      // 点击编辑
      editClick(row) {
        proxy.dialogTitleName = "编辑方案";
        proxy.chargeId = row.chargeId;
        data.FormData.chargeName = row.chargeName;
        // console.log(row.chargeScheme,'计费');
        data.FormData.chargeScheme = methods.chargeType(row.chargeScheme);
        // data.detailNum = JSON.parse(row.chargeDuration);
        data.FormData.chargeDuration = row.chargeDuration;
        data.FormData.chargeMoney = row.chargeMoney;
        data.dialogVisible = true;
      },
      // 保存提交
      async submitEditForm(formName) {
        proxy.$refs[formName].validate(async (valid) => {
          if (valid) {
            if (proxy.dialogTitleName === "新增方案") {
              await methods.insertFrom();
            } else {
              await methods.editForm();
            }
          }
        });
      },
      // 取消
      resetEditForm() {
        data.dialogVisible = false;
        data.FormData = {
          chargeName: "", // 方案名称
          chargeScheme: 2, // 选中的计时类型
          // chargeDuration: [], // 方案详情
          chargeDuration: 1, // 时间
          chargeMoney: 0.5, //金额
        };
      },
      // 搜索
      searchClick() {
        methods.getChargePages();
      },
         // 表格分页 改变每页数量时触发
      handleSizeChange(val) {
        proxy.pageSize = val;
        methods.getChargePages();
      },
      // 表格分页 改变页数时触发
      handleCurrentChange(val) {
        proxy.currentPage = val;
        methods.getChargePages();
      },
      // 分页查询计费方案
      async getChargePages() {
        await requestApi(
          () => {
            const params = {
              chargeName: proxy.chargeNameValue,
              current: data.currentPage,
              size: data.pageSize,
              chargeType:0,//0 计时 1 电量
            };
            return chargePagesApi(params);
          },
          (res) => {
            data.tableData = res.data.records.map((it) => {
              // const arr = JSON.parse(it.chargeDuration);
              // let schemeDetails;
              // let schemeString;
              // for (let i = 0; i < arr.length; i++) {
              //   schemeString = `${arr[i].atime}小时${arr[i].price}元`;
              //   if (i > 0) {
              //     schemeDetails = schemeDetails + ";" + schemeString;
              //   } else {
              //     schemeDetails = schemeString;
              //   }
              // }
              return {
                chargeId: it.chargeId,
                chargeName: it.chargeName,
                createUser: it.createUser,
                createTime: it.createTime,
                chargeScheme: methods.chargeType(it.chargeScheme),
                chargeDuration: it.chargeDuration,
                schemeDetails: `${it.chargeDuration}小时,${it.chargeMoney}元`,
                chargeMoney: it.chargeMoney,
              };
            });
            data.currentPage = res.data.current;
            data.pageSize = res.data.size;
            data.total = res.data.total;
          }
        );
      },
      // 新增计费方案
      async insertFrom() {
        await requestApi(
          () => {
            const params = {
              chargeName: proxy.FormData.chargeName,
              chargeScheme: proxy.FormData.chargeScheme,
              chargeDuration: proxy.FormData.chargeDuration,
              chargeMoney: proxy.FormData.chargeMoney,
              // chargeDuration: JSON.stringify(proxy.detailNum),
              chargeType: 0, // 0 计时方案, 1 电量方案
            };
            return insertAddApi(params);
          },
          (res) => {
            if (res.code == 200) {
              proxy.$notify({
                title: "成功",
                message: res.message,
                type: "success",
                duration: 1000,
              });
              data.dialogVisible = false;
              methods.getChargePages();
            }
          }
        );
      },
      // 修改方案
      async editForm() {
        await requestApi(
          () => {
            const params = {
              chargeName: proxy.FormData.chargeName,
              chargeScheme: proxy.FormData.chargeScheme,
              // chargeDuration: JSON.stringify(proxy.detailNum),
              chargeDuration: proxy.FormData.chargeDuration,
              chargeMoney: proxy.FormData.chargeMoney,
              chargeType: 0, // 0 计时方案, 1 电量方案
              chargeId: proxy.chargeId,
            };
            return updateApi(params);
          },
          (res) => {
            if (res.code == 200) {
              proxy.$notify({
                title: "成功",
                message: res.message,
                type: "success",
                duration: 1000,
              });
              data.dialogVisible = false;
              methods.getChargePages();
            }
          }
        );
      },
      // 删除方案
      async confirmEvent(id) {
        await requestApi(
          () => {
            const params = {
              Ids: id.chargeId,
            };
            return deleteApi(params);
          },
          (res) => {
            if (res.code == 200) {
              proxy.$notify({
                title: "成功",
                message: res.message,
                type: "success",
                duration: 1000,
              });
              methods.getChargePages();
            }
          }
        );
      },
      // 计时类型表格显示
      chargeType(type) {
        if (type == 1) {
          return "30分钟收费";
        } else if (type == 2) {
          return "每小时收费";
        } else if (type == 0) {
          return "1分钟收费";
        } else if (type == "每小时收费") {
          return 2;
        } else if (type == "30分钟收费") {
          return 1;
        } else if (type == "1分钟收费") {
          return 0;
        }
      },
    };
    return {
      ...toRefs(data),
      searchInput,
      ...methods,
    };
  },
};
</script>
<style lang="less" scoped>
.warp {
  height: 100%;
  .warp-content {
    height: 100%;
  }
}
.page-select {
  width: 100%;
  margin: 0 auto;
}
.page-container {
  width: 100%;
  //margin-top: -28px;
  .imgeTou {
    width: 35px;
    height: 35px;
  }
}
.page-foot {
  width: 100%;
  margin-top: 14px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .my-btn {
    height: 28px;
  }
}
.search-input {
  width: 202px;
  margin-right: 4px;
  margin-top: 6px;
}
//.search-select{
//  width: 100%;
//}
.search-input-class {
  width: 100%;
}
/deep/.el-form-item__label {
  white-space: nowrap;
}
/deep/.el-form-item {
  width: 100%;
}
</style>
